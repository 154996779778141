@import '../../colours';
@import '../../breakpoints';

.job {
  padding-bottom: 15px;
  &__title {
    color: $white;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &__inner {
    width: 100%;
    background-color: $off-white;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding: 15px;
    &--content {
      width: 95%;
      margin: 0 auto;
    }
  }
  &__back {
    width: 125px;
    font-size: 14px;
    padding: 5px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  &__info {
    margin-bottom: 15px;
    background: $white;
    padding: 12px;
    min-height: 100px;
    -moz-box-shadow: 0 0 3px rgba($grey, 0.4);
    -webkit-box-shadow: 0 0 3px rgba($grey, 0.4);
    box-shadow: 0 0 3px rgba($grey, 0.4);
    &--company-name {
      color: $orange;
      font-weight: 800;
      margin-bottom: 3px;
      font-size: 14px;
    }
    &--type,
    &--dates,
    &--money,
    &--location,
    &--certification,
    &--occupations,
    &--additional-location-details,
    &--accommodation-included,
    &--loyalty-bonus {
      color: $blue;
      margin-bottom: 3px;
      font-size: 14px;
      font-weight: 600;
    }
    &--accommodation-included {
      margin-bottom: 7.5px;
      padding-bottom: 7.5px;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
      font-weight: 700;
    }
    &--loyalty-bonus {
      margin-bottom: 0;
      padding-bottom: 0;
      font-weight: 700;
    }
    &--certification {
      margin-bottom: 7.5px;
      padding-bottom: 7.5px;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    }
    &--description {
      color: $grey;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 3px;
    }
    &--created-at {
      font-size: 11px;
      color: $blue;
      font-weight: 600;
    }
  }
  &__apply {
    font-size: 14px;
    padding: 12px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 8px;
  }
}

@media (min-width: $breakpoint-tablet) {
  .job {
    &__apply {
      width: 300px;
    }
    &__info {
      padding: 40px;
      &--certification {
        margin-bottom: 25px;
      }
      &--created-at {
        margin-top: 15px;
        font-size: 13px;
        color: $blue;
        font-weight: 600;
      }
    }
  }
}
