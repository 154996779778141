.cookie-consent {
  width: 100%;
  position: fixed;
  min-height: 50px;
  text-align: center;
  padding: 15px;
  bottom: 0;
  right: 0;
  background-color: white;
  z-index: 99;
  border-top: 0.5px solid rgba(#333, 0.5);
  small {
    float: left;
    margin: 0 auto;
  }
  button.btn-link {
    position: absolute;
    right: 4px;
    top: 0;
    cursor: not-allowed;
    padding: 0;
    color: black;
  }
  button.btn-outline-dark {
    padding: 2px;
    font-size: 12px;
    width: 50px;
    margin-left: 8px;
  }
}
