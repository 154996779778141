@import '../../colours';
@import '../../breakpoints';

.footer {
  width: 100%;
  float: left;
  background-color: $blue;
  min-height: 100px;
  color: $white;
  height: 200px;
  position: absolute;
  bottom: 0;
  padding: 8px;
  padding-top: 5px;
  text-align: center;
  a {
    color: $white;
  }
  p {
    font-size: 10px;
    margin-bottom: 5px;
  }
  hr {
    border-color: $white;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

@media (min-width: $breakpoint-mobile-xl) {
  .footer {
    padding-top: 10px;
    text-align: left;
    min-height: 120px;
    height: 120px;
    padding-left: 15px;
    p {
      font-size: 12px;
      small {
        font-size: 12px;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .footer {
    min-height: 80px;
    height: 80px;
  }
}
