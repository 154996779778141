@import '../../breakpoints';
@import '../../colours';

.post-job {
  &__inner {
    border-radius: 0;
    padding-top: 0;
    background-color: $off-white;
  }
  &__title {
    color: #333;
    text-align: center;
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
