@import '../../colours';
@import '../../breakpoints';

.post-job-form {
  .date-range {
    width: 100%;
    .DateRangePicker {
      width: 100%;
      .DateRangePickerInput {
        width: 100%;
        .DateInput {
          width: 45%;
          &_input {
            font-size: 16px;
            text-align: center;
          }
        }
      }
    }
  }
  .container {
    max-width: 100%;
  }
  .nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    a {
      padding-top: 10px;
      width: calc(100% / 2);
      color: $grey;
      &.active {
        color: $orange;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-left: 1px solid rgba(0, 0, 0, 0.2);
        border-right: 1px solid rgba(0, 0, 0, 0.2);
      }
      &:nth-child(2) {
        padding-top: 16px;
      }
      h1 {
        text-align: center;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
  &--next,
  &--back,
  &--submit {
    margin-bottom: 20px;
    float: left;
  }
}

@media (min-width: $breakpoint-tablet) {
  .post-job-form {
    .date-range {
      .DateRangePicker {
        .DateRangePickerInput {
          .DateInput {
            width: 47%;
          }
        }
      }
    }
    .container {
      max-width: 80%;
    }
    .nav-tabs {
      a {
        width: calc(80% / 2);
        padding-top: 18px;
        &:nth-child(1) {
          margin-left: auto;
        }
        &:nth-child(2) {
          margin-right: auto;
        }
        h1 {
          font-size: 16px;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .post-job-form {
    .container {
      max-width: 60%;
    }
    .nav-tabs {
      a {
        &:nth-child(1) {
          margin-left: auto;
        }
        &:nth-child(2) {
          margin-right: auto;
        }
        width: calc(60% / 2);
        h1 {
          font-size: 18px;
        }
      }
    }
  }
}
