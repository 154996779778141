@import '../../_colours.scss';
@import '../../breakpoints';

.login {
  // min-height: calc(100vh - 62px);
  // background: url('../../images/LandingPage/homepage-1.jpg');
  // background-repeat: no-repeat;
  // background-size: cover;
  // position: relative;
  // z-index: -1;
  .overlay {
    // position: absolute;
    // width: 100%;
    // height: 100%;
    // background-color: rgba(black, 0.65);
    // z-index: -1;
  }
  .content {
    // width: 90%;
    // margin: 0 auto;
    // padding-top: 20px;
    // padding-bottom: 20px;
  }
  &__title {
    text-align: center;
    color: $white;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 20px;
  }
  &__title-icon {
    width: 50px;
    margin-bottom: 12px;
  }
  &-form {
    .card-header {
      padding: 8px;
      cursor: pointer;
    }
    .form-control,
    .css-yk16xz-control {
      border-radius: 0;
    }
    &__label {
      text-align: left;
      color: $white;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    &__subtitle {
      font-weight: 800;
      font-size: 25px;
      color: $orange;
      margin: 0;
    }
    small {
      text-align: center;
      font-size: 14px;
      width: 100%;
      float: left;
      margin-top: 10px;
      font-weight: 700;
      a {
        color: $white;
        text-align: center;
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .login {
    .content {
      width: 40%;
    }
  }
}

// @media (max-width: 480px) {
//   .login {
//     width: 100%;
//   }
//   .login-form {
//     &__submit {
//       width: 100%;
//     }
//     &__subtitle {
//       text-align: center;
//       font-size: 16px;
//     }
//     &__label,
//     .form-control {
//       font-size: 14px;
//     }
//   }
// }
