@import '../../colours';
@import '../../breakpoints';

.custom-button {
  width: 100%;
  color: $white;
  background-color: $blue;
  padding: 15px 12px;
  border: 1px solid $blue;
  cursor: pointer;
  &.primary {
    background-color: $orange;
    color: $white !important;
    border: 1px solid $orange;
  }
  &.outline {
    background-color: transparent !important;
    border: 3px solid $orange !important;
    color: $orange !important;
  }
  &:focus {
    border: none;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

@media (max-width: $breakpoint-mobile-xl) {
  .DateRangePicker_picker__directionLeft {
    left: 0 !important;
  }
}
