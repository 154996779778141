@import '../../colours';
@import '../../breakpoints';

@media (min-width: $breakpoint-mobile-xs) {
  .navbar {
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;
    background-color: $white;
    .navbar-brand {
      padding-left: 0;
      margin-left: 0;
      padding-right: 0;
      margin-right: 0;
      img {
        width: auto;
        height: 40px;
      }
    }
    .custom-button {
      text-align: center;
      width: 80%;
      margin: 0 auto;
      border-radius: 100px;
      font-weight: 700;
      background-color: $orange;
      padding: 0 20px;
      color: $white;
      height: 40px;
      margin-bottom: 15px;
      font-size: 14px;
      #nav-register {
        padding: 0 !important;
        color: $white;
        text-decoration: none;
        &:hover {
          text-decoration: none;
          color: $white;
        }
      }
      #nav-login {
        padding: 0 !important;
        color: $orange;
        text-decoration: none;
        &:hover {
          text-decoration: none;
          color: $orange;
        }
      }
    }
    .navbar-toggler {
      border: none;
      position: relative;
      padding-top: 0;
      cursor: pointer;
      &:hover {
        outline: none;
      }
      &::after {
        content: 'Menu';
        font-size: 12px;
        color: $orange;
        font-weight: 700;
        position: absolute;
        bottom: -5px;
        left: 20%;
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .navbar {
    text-align: left;
    .navbar-brand {
      img {
        height: 36px;
      }
    }
    .nav-link {
      text-align: center;
      font-size: 14px;
    }
    .custom-button {
      width: inital;
      font-size: 16px;
      margin-bottom: 0;
      font-size: 14px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .navbar {
    .custom-button {
      &.nav-register-btn {
        width: 160px;
        margin-left: 20px;
      }
      &.nav-login-btn {
        width: 120px;
        margin-left: 12px;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop-xl) {
  .navbar {
    .custom-button {
      font-size: 16px;
    }
    .nav-link {
      font-size: 16px;
    }
  }
}

// Random media query (not bootstrap standard breakpoint)
@media (max-width: 1100px) {
  .navbar {
    #supplier-link {
      padding-top: 18px;
    }
    .nav-login-btn,
    .nav-register-btn {
      margin-top: 8px;
    }
  }
}
