.modal {
  //   .modal-header {
  //     &.draggable {
  //       cursor: grab;
  //     }
  //     .modal-title {
  //       font-weight: 700;
  //     }
  //     button.close {
  //       opacity: 1;
  //     }
  //   }
  //   .modal-body {
  //     min-height: 100px;
  //   }
  //   .modal-content {
  //     padding: 15px;
  //   }
  //   .modal-footer {
  //     justify-content: inherit !important;
  //     display: block !important;
  //   }
  .close {
    font-size: 2.2em;
  }
  .modal-dialog {
    max-height: 700px;
    &.react-draggable-dragging {
      transition: none;
    }
    &.modal-10w {
      max-width: none !important;
      width: 10%;
    }
    &.modal-20w {
      max-width: none !important;
      width: 20%;
    }
    &.modal-30w {
      max-width: none !important;
      width: 30%;
    }
    &.modal-40w {
      max-width: none !important;
      width: 40%;
    }
    &.modal-50w {
      max-width: none !important;
      width: 50%;
    }
    &.modal-60w {
      max-width: none !important;
      width: 60%;
    }
    &.modal-70w {
      max-width: none !important;
      width: 70%;
    }
    &.modal-80w {
      max-width: none !important;
      width: 80%;
    }
    &.modal-90w {
      max-width: none !important;
      width: 90%;
    }
    &.modal-100w {
      max-width: none !important;
      // width: 100%;
    }
  }
}

// @media (max-width: 768px) {
//   .modal {
//     .modal-content {
//       padding: 0;
//     }
//     .modal-dialog {
//       margin: 0;
//     }
//   }
// }
