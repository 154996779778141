@import '../../../colours';
@import '../../../breakpoints';

.supplies_feed {
  padding-bottom: 15px;
  &__post {
    width: 100%;
    -moz-box-shadow: 0 0 3px rgba($grey, 0.4);
    -webkit-box-shadow: 0 0 3px rgba($grey, 0.4);
    box-shadow: 0 0 3px rgba($grey, 0.4);
    padding: 12px;
    position: relative;
    padding-left: 15px;
    min-height: 155px;
    margin-bottom: 25px;
    // text-align: center;
    padding-top: 35px;
    &--type {
      font-size: 14px;
      color: white;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 30px;
      line-height: 30px;
      font-weight: 700;
      // position: absolute;
      // bottom: 0;
      // left: 0;
      // // margin-left: -18px;
      // // -webkit-transform: rotate(270deg);
      // // -moz-transform: rotate(270deg);
      // // -ms-transform: rotate(270deg);
      // // -o-transform: rotate(270deg);
      // // transform: rotate(270deg);
      // // -webkit-transform-origin: 0 0;
      // // -moz-transform-origin: 0 0;
      // // -ms-transform-origin: 0 0;
      // // -o-transform-origin: 0 0;
      // // transform-origin: 0 0;
      // top: 95%;
      // font-weight: 700;
    }
    &--title {
      font-size: 24px;
      color: $orange;
      font-weight: 700;
      margin-bottom: 2px;
    }
    &--account,
    &--date {
      font-weight: 600;
      color: $blue;
      font-size: 14px;
    }
    &--account {
      a {
        font-weight: 600;
        color: $blue;
        font-size: 14px;
      }
    }
    &--date {
      font-size: 12px;
      margin-bottom: 0;
    }
    &--desc {
      font-weight: 700;
      color: $blue;
      font-size: 14px;
      button {
        font-size: inherit;
        border: none;
        padding-left: 10px !important;
      }
    }
    &--image {
      width: 33.3333%;
      margin-right: 15px;
      height: 100%;
      margin-bottom: 8px;
    }
  }
  &__dropzone {
    border: 1px dashed #333;
    color: #333;
    padding: 15px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 25px;
    text-align: center;
    margin-top: 12px;
    &.dark {
      color: #333;
      border: 1px dashed #333;
    }
    p {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .supplies_feed {
    &__create {
      width: 60%;
      margin: 0 auto;
    }
    &__post {
      &--image {
        max-width: 100%;
        width: auto;
        height: 90px;
      }
    }
  }
}
