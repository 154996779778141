@import '../../../colours';
@import '../../../breakpoints';

.feed {
  &__job {
    background-color: $white;
    margin-bottom: 20px;
    padding: 15px;
    -moz-box-shadow: 0 0 3px rgba($grey, 0.4);
    -webkit-box-shadow: 0 0 3px rgba($grey, 0.4);
    box-shadow: 0 0 3px rgba($grey, 0.4);
    min-height: 400px;
    position: relative;
    &__title {
      font-size: 18px;
      color: $orange;
      font-weight: 700;
    }
    &__placement-type,
    &__dates,
    &__pay-details,
    &__location,
    &__certification,
    &__occupations {
      color: $blue;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0;
    }
    &__description {
      margin-top: 12px;
      width: 100%;
      float: left;
      height: 100px;
      overflow: hidden;
      color: $grey;
      margin-bottom: 15px;
    }
    &__created-at {
      color: $blue;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 0;
      margin-bottom: 15px;
    }
    &__view-button {
      width: 95%;
      margin: 0 auto;
      font-size: 18px;
      padding-top: 8px;
      padding-bottom: 8px;
      position: absolute;
      bottom: 15px;
      left: 2.5%;
      a {
        color: $white;
      }
    }
  }

  &__filter {
    .clear-all-btn {
      border-radius: 20px;
      font-size: 14px;
      min-width: 30px;
      padding: 2px 8px;
      margin-bottom: 15px;
    }
    .filter-btn {
      width: 100%;
    }
    .custom-button {
      padding: 5px 3px;
    }
    &__container {
      border-radius: 15px;
      background-color: $off-white;
      padding: 15px 25px;
      .custom-button {
        padding: 5px 3px;
        margin-top: 30px;
      }
    }
  }
}

.filter-button {
  padding: 8px 5px;
}
.tags-container {
  width: 100%;
  float: left;
  margin-bottom: 5px;
  p {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
  }
  .selected-region-tag {
    background-color: hsl(0, 0%, 90%);
    border-radius: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
    float: left;
    margin-bottom: 5px;
    padding: 0 5px;
    .tag {
      border-radius: 2px;
      color: hsl(0, 0%, 20%);
      font-size: 13px;
      overflow: hidden;
      padding: 3px;
      padding-left: 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
      max-width: 75px;
    }
    .close {
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 2px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding-left: 4px;
      padding-right: 4px;
      box-sizing: border-box;
      i {
        font-size: 12px;
        padding-top: 2.5px;
      }
    }
  }
}
// @media (min-wid)
