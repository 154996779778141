@import '../../colours';
@import '../../breakpoints';

.feed {
  background: url('../../images/LandingPage/homepage-1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 15px;
  min-height: 100vh;
  padding-bottom: 50px;
  &__title {
    font-size: 24px;
    color: $white;
    text-align: center;
    font-weight: 600;
    margin-bottom: 25px;
    margin-top: 15px;
  }
  &__inner {
    background-color: $white;
    border-radius: 30px;
    padding: 25px;
    padding-top: 15px;
    &__title {
      font-size: 22px;
      text-align: center;
      font-weight: 700;
      color: $orange;
      margin-bottom: 12px;
    }
  }

  &__filter {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (min-width: $breakpoint-tablet) {
  .feed {
    &__title {
      font-size: 36px;
    }
    &__inner {
      &__title {
        font-size: 26px;
      }
    }
  }
}
