@import '../../colours';
@import '../../breakpoints';

@media (min-width: $breakpoint-mobile-xs) {
  .landing {
    &__section-divider {
      width: 100%;
      float: left;
      height: 20px;
      background-color: $blue;
      border-top: 1px solid $grey;
      border-bottom: 1px solid $grey;
    }
    &__section {
      width: 100%;
      float: left;
      // padding: 15px 30px;
      position: relative;
      z-index: 1;
      padding-top: 50px;
      padding-bottom: 40px;
      // padding-left: -15px;
      // padding-right: -15px;
      &--1,
      &--3,
      &--4,
      &--5,
      &--6,
      &--7 {
        .custom-button {
          border-radius: 100px;
          font-weight: 700;
          background-color: $orange;
          width: 100%;
          float: left;
          margin: 0 auto;
          margin-bottom: 10px;
          a {
            color: $white;
            text-decoration: none;
            &:hover {
              text-decoration: none;
              color: $white;
            }
          }
        }
      }
      &--1 {
        padding-top: 20px;
        background: url('../../images/LandingPage/homepage-1.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(100vh - 62px);
        &__overlay {
          background-color: rgba(0, 0, 0, 0.7);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
        &__content {
          h1 {
            color: $orange;
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 26px;
          }
          p {
            color: $white;
            font-size: 16px;
            margin-bottom: 30px;
            font-weight: 600;
            &:nth-of-type(2) {
              text-align: center;
              margin-bottom: 35px;
            }
          }
          .custom-button {
            border-radius: 100px;
            font-weight: 700;
            background-color: $orange;
            width: 100%;
            float: left;
            margin: 0 auto;
            margin-bottom: 10px;
            a {
              color: $white;
              text-decoration: none;
              &:hover {
                text-decoration: none;
                color: $white;
              }
            }
          }
          a {
            color: $orange;
          }
          .btn-cont {
            width: 100%;
            float: left;
            .btn {
              border-radius: 100px;
              text-align: center;
              width: 50px;
              height: 50px;
              cursor: pointer;
              .fas {
                font-size: 30px;
                padding-top: 4px;
                font-weight: 600;
              }
            }
            small {
              color: white;
              float: left;
              width: 100%;
              font-size: 14px;
              padding-top: 5px;
            }
          }
        }
      }
      &--2 {
        background: url('../../images/LandingPage/homepage-logo-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 120px;
        &__overlay {
          background-color: rgba(255, 255, 255, 0.7);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
        &__content {
          h2 {
            color: $grey;
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 24px;
          }
          p {
            color: $grey;
            font-size: 16px;
            margin-bottom: 30px;
            font-weight: 600;
          }
        }
      }
      &--3 {
        background: url('../../images/LandingPage/homepage-2.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        &__overlay {
          background-color: rgba(0, 0, 0, 0.7);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
        &__content {
          h2 {
            color: $orange;
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 26px;
          }
          p {
            color: $white;
            font-size: 16px;
            margin-bottom: 30px;
            font-weight: 600;
          }
        }
      }
      &--4 {
        background: url('../../images/LandingPage/homepage-3.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        &__overlay {
          background-color: rgba(255, 255, 255, 0.8);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
        &__content {
          h2 {
            color: $orange;
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 24px;
          }
          p {
            color: $grey;
            font-size: 16px;
            margin-bottom: 30px;
            font-weight: 600;
          }
        }
      }
      &--5 {
        background: url('../../images/LandingPage/homepage-4.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        &__overlay {
          background-color: rgba(0, 0, 0, 0.7);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
        &__content {
          h2 {
            color: $orange;
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 26px;
          }
          p {
            color: $white;
            font-size: 16px;
            margin-bottom: 30px;
            font-weight: 600;
          }
        }
      }
      &--6,
      &--7 {
        background: url('../../images/LandingPage/homepage-3.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        &__overlay {
          background-color: rgba(255, 255, 255, 0.8);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
        &__content {
          h2 {
            color: $orange;
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 24px;
          }
          p {
            color: $grey;
            font-size: 16px;
            margin-bottom: 30px;
            font-weight: 600;
          }
        }
      }
      &--7 {
        background: url('../../images/LandingPage/homepage-1.jpg');
        .btn {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-mobile-md) {
  .landing {
    &__section {
      &--1 {
        padding: 50px;
      }
    }
  }
}

@media (min-height: $breakpoint-mobile-plus-size-height-min) and (max-height: $breakpoint-mobile-plus-size-height-max) {
  .landing {
    &__section {
      &--1 {
        padding-top: 100px;
        &__content {
          h1 {
            margin-bottom: 70px;
          }
          p {
            margin-bottom: 70px;
          }
        }
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .landing {
    &__section {
      &--7 {
        text-align: center;
        .custom-button {
          width: 50%;
          display: flex;
          justify-content: center;
          float: none;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-mobile-xl) {
  .landing {
    &__section {
      &--3,
      &--4,
      &--5,
      &--6,
      &--7 {
        &__content {
          padding-left: 30px;
          padding-right: 30px;
          h2 {
            font-size: 30px;
          }
          &--container {
            height: 400px;
            max-height: 400px;
          }
          .custom-button {
            font-size: 18px;
          }
        }
      }
      &--7 {
        &__content {
          &--container {
            // min-height: 600px;
            max-height: none;
            height: auto;
          }
        }
      }
      &--1 {
        &__content {
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          position: absolute;
          text-align: center;
          h1 {
            font-size: 36px;
          }
          p {
            margin-bottom: 60px;
            font-size: 20px;
            &:nth-of-type(2) {
              margin-bottom: 35px;
              font-size: 16px;
            }
          }
          .custom-button {
            width: 380px;
            float: none;
            font-size: 24px;
          }
        }
      }
      &--2 {
        padding-top: 100px;
        padding-bottom: 100px;
        text-align: center;
        background-position-x: 120%;
        background-size: contain;
        h2 {
          font-weight: 800;
          font-size: 32px;
        }
        p {
          font-size: 18px;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .landing {
    &__section {
      &--1 {
        &__content {
          width: 60%;
          h1 {
            font-size: 52px;
          }
          p {
            font-size: 32px;
            &:nth-of-type(2) {
              font-size: 24px;
            }
          }
          .custom-button {
            width: 500px;
            font-size: 32px;
          }
          .btn-cont {
            small {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $breakpoint-desktop-xl) {
  .landing {
    &__section {
      &--1 {
        &__content {
          width: 60%;
          h1 {
            font-size: 36px;
          }
          p {
            font-size: 20px;
            &:nth-of-type(2) {
              font-size: 18px;
            }
          }
          .custom-button {
            width: 500px;
            font-size: 20px;
          }
          .btn-cont {
            small {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

html {
  scroll-behavior: smooth;
}
