#root {
  padding-top: 0 !important;
}

.reviews-table {
  .rt-tr {
    cursor: pointer;
  }
}

.post-accommodation {
  label {
    color: #333;
  }
}

.downloadDataButton {
  a {
    color: white;
  }
}

.create-accommodation-image-container {
  position: relative;
  .remove-image {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 33px !important;
    padding: 0 !important;
  }
}
