@import '../../colours';
@import '../../breakpoints';

.job-card {
  background-color: $white;
  margin-bottom: 20px;
  padding: 15px;
  -moz-box-shadow: 0 0 3px rgba($grey, 0.4);
  -webkit-box-shadow: 0 0 3px rgba($grey, 0.4);
  box-shadow: 0 0 3px rgba($grey, 0.4);
  min-height: 400px;
  position: relative;
  &__title {
    font-size: 18px;
    color: $orange;
    font-weight: 700;
  }
  &__placement-type,
  &__dates,
  &__pay-details,
  &__location,
  &__certification,
  &__occupations {
    color: $blue;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
  }
  &__description {
    margin-top: 12px;
    width: 100%;
    float: left;
    height: 100px;
    overflow: hidden;
    color: $grey;
    margin-bottom: 15px;
  }
  &__created-at {
    color: $blue;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
    margin-bottom: 15px;
  }
  &__view-button {
    width: 95%;
    margin: 0 auto;
    font-size: 18px;
    padding-top: 8px;
    padding-bottom: 8px;
    position: absolute;
    bottom: 15px;
    left: 2.5%;
    a {
      color: $white;
    }
  }
}
