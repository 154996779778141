@import './colours';
@import './breakpoints';

.loader {
  display: flex;
  padding-top: 20px;
  :first-child {
    margin: 0 auto;
  }
}

@media (min-width: $breakpoint-mobile-xs) {
  .Toastify__toast {
    &-container {
      top: 60px;
    }
    &-body {
      width: 100%;
      word-break: break-word;
    }
    &--info {
      background: rgba($grey, 0.9);
      border: 1px solid black;
      font-size: 16px;
      font-weight: 700;
      a {
        color: rgba($white, 0.7);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
          color: $white;
        }
      }
    }
  }
  .nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    a {
      padding: 8px;
      padding-top: 10px;
      color: $grey;
      &.active {
        color: $orange;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-left: 1px solid rgba(0, 0, 0, 0.2);
        border-right: 1px solid rgba(0, 0, 0, 0.2);
      }
      h1 {
        text-align: center;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
  .content {
    &.centered {
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      position: absolute;
      width: 90%;
    }
  }
  .background {
    min-height: calc(100vh - (66px + 200px));
    background: url('./images/LandingPage/homepage-1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: -1;
    overflow: auto;
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(black, 0.65);
      z-index: -1;
    }
    &__inner {
      width: 100%;
      background-color: $off-white;
      border-radius: 10px;
      padding-top: 40px;
      padding: 15px;
      &--content {
        width: 95%;
        margin: 0 auto;
      }
      &__subtitle {
        font-size: 18px;
        color: $blue;
        font-weight: 600;
        border-bottom: 0.5px solid rgba($blue, 0.3);
      }
    }
    &__title {
      color: $white;
      font-weight: 600;
      text-align: center;
      font-size: 32px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .content {
      &.centered {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        width: 90%;
      }
    }
  }
}

@media (min-width: $breakpoint-mobile-xl) {
  .content {
    &.centered {
      top: calc(50% - 31px);
      width: 70%;
    }
  }
  .background {
    min-height: calc(100vh - (62px + 120px));
    .content {
      &.centered {
        top: calc(50% - 31px);
        width: 70%;
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .content {
    &.centered {
      width: 40%;
    }
  }
  .background {
    // min-height: calc(100vh - (62px + 80px));
    &__inner {
      padding: 15px;
    }
    .content {
      &.centered {
        width: 40%;
      }
    }
  }
  .nav-tabs {
    a {
      padding-top: 18px;
      h1 {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .background {
    min-height: calc(100vh - (62px + 80px));
  }
}

@media (min-height: $breakpoint-mobile-landscape-height-min) and (max-height: $breakpoint-mobile-landscape-height-max) {
  .content {
    padding-top: 15px;
    width: 90%;
    margin: 0 auto;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    transform: initial !important;
  }
  .background {
    padding-bottom: 15px;
    .content {
      padding-top: 15px;
      width: 90%;
      margin: 0 auto;
      position: relative !important;
      top: auto !important;
      left: auto !important;
      transform: initial !important;
    }
  }
}

.ql-editor {
  background-color: $white;
}
