@import '../../colours';
@import '../../breakpoints';

.posted-jobs {
  padding-bottom: 15px;
  &__title {
    color: $white;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &__inner {
    width: 100%;
    background-color: $off-white;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding: 15px;
    padding-top: 40px;
    &--content {
      width: 95%;
      margin: 0 auto;
    }
  }
  &__job {
    background-color: $white;
    margin-bottom: 20px;
    padding: 15px;
    -moz-box-shadow: 0 0 3px rgba($grey, 0.4);
    -webkit-box-shadow: 0 0 3px rgba($grey, 0.4);
    box-shadow: 0 0 3px rgba($grey, 0.4);
    min-height: 150px;
    position: relative;
    .expired {
      &__overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: rgba($grey, 0.8);
        z-index: 1;
        top: 0;
        left: 0;
      }
      &__content {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        width: 100%;
        z-index: 1;
        h2 {
          color: $white;
          font-weight: 600;
          text-align: center;
          font-size: 32px;
        }
      }
    }
    &__title {
      font-size: 18px;
      color: $orange;
      font-weight: 700;
    }
    &__placement-type,
    &__dates,
    &__pay-details {
      color: $blue;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0;
    }
    &__pay-details {
      margin-bottom: 15px;
    }
    &__applied-date {
      margin-top: 15px;
      font-size: 12px;
    }
    &__status {
      color: $blue;
      font-weight: 800;
      font-size: 16px;
      margin-bottom: 0;
    }
    &__view-button {
      width: 100%;
      font-size: 18px;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-top: 15px;
      a {
        color: $white;
      }
    }
  }
  &__back {
    width: 200px;
    font-size: 14px;
    padding: 5px;
    margin-bottom: 15px;
    margin-top: 15px;
    &.applied {
      text-align: center;
    }
  }
  &__info {
    margin-bottom: 15px;
    background: $white;
    padding: 12px;
    min-height: 100px;
    -moz-box-shadow: 0 0 3px rgba($grey, 0.4);
    -webkit-box-shadow: 0 0 3px rgba($grey, 0.4);
    box-shadow: 0 0 3px rgba($grey, 0.4);
    &--reference {
      color: $orange;
      font-weight: 800;
      margin-bottom: 20px;
      font-size: 14px;
    }
    .form-group {
      label {
        font-size: 12px;
      }
    }
  }
  &__apply {
    font-size: 14px;
    padding: 12px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 8px;
  }
}

@media (min-width: $breakpoint-tablet) {
  .job {
    &__apply {
      width: 300px;
    }
    &__info {
      padding: 40px;
      &--certification {
        margin-bottom: 25px;
      }
      &--created-at {
        margin-top: 15px;
        font-size: 13px;
        color: $blue;
        font-weight: 600;
      }
    }
  }
}
